import Vue from 'vue';
// 滚动窗口显示对象时执行动画
const animateScroll = Vue.directive('scroll', {
    inserted: function (el, binding) {
        const observer = new IntersectionObserver(
            (entries, observer) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        const animationClass = binding.value;
                        el.classList.add(animationClass);
                        observer.unobserve(el);
                    }
                });
            },
            {
                rootMargin: "0px",
                threshold: 0.1,
            }
        );
        observer.observe(el);
    }
})
export default animateScroll
